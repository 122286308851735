export default function Header() {
  const links = [
    {
      label: "Marketplace",
      href: "https://disruptiverse.net/",
    },
    {
      label: "Metaverso",
      href: "https://app.disruptiverse.com/",
    },
    // {
    //   label: "Planet W",
    //   href: "https://planetw.io/#planet-d",
    // },
    // {
    //   label: "Mis NFT's",
    //   href: "https://disruptiverse.com/nftBalance",
    // },
  ];
  return (
    <div className="bg-black text-white flex items-center text-[16px]">
      <div className="flex items-center">
        <img className="h-[40px] w-auto" src="/logo.svg" alt="logo"></img>
        <div className="m-2 p-2 font-bold hover:text-[#6240f5]">
          {"DISRUPTIVERSE"}
        </div>
      </div>
      <div className="flex flex-1 justify-end mr-10">
        {links.map((it, index) => (
          <a key={index} href={it.href} target="_blank" rel="noreferrer">
            <div className="m-2 p-2 font-bold hover:text-[#6240f5]">
              {it.label}
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
