import { useEffect, useState } from "react";
import {
  CHAIN_ID_NETWORK,
  // LANDS_COLLECTION_ADDRESS,
  MORALIS_APPLICATION_ID,
  MORALIS_SERVER_URL,
} from "../constants";

export default function useNFTs() {
  const [nfts, setNFTs] = useState<any[]>();
  const [erc20Tokens, setErc20Tokens] = useState<any[]>();

  useEffect(() => {
    fetch(`${MORALIS_SERVER_URL}/getAllLands`, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        // address: LANDS_COLLECTION_ADDRESS,
        chainId: CHAIN_ID_NETWORK,
        limit: 100000,
        _ApplicationId: MORALIS_APPLICATION_ID,
      }), // body data type must match
    }).then((result) => {
      if (result.ok) {
        result.json().then((result) => {
          if (result && result.result && result.result[0]) {
            const nftsWithPosition = result.result[0].results.filter(
              (it: any) => it.attributes
            );
            const nfts = nftsWithPosition.map((nft: { attributes: string }) => {
              try {
                const attributes = JSON.parse(nft.attributes);
                let latitudeAttribute = attributes.find(
                  (it: { trait_type: string }) => it.trait_type === "latitude"
                );
                let longitudeAttribute = attributes.find(
                  (it: { trait_type: string }) => it.trait_type === "longitude"
                );
                let latitude =
                  latitudeAttribute && latitudeAttribute.value
                    ? Number(latitudeAttribute.value)
                    : 0;
                let longitude =
                  longitudeAttribute && longitudeAttribute.value
                    ? Number(longitudeAttribute.value)
                    : 0;
                return {
                  ...nft,
                  ...{
                    coordinates: {
                      lat: latitude,
                      lng: longitude,
                    },
                    attributesList: attributes,
                  },
                };
              } catch (e) {
                console.warn(e, nft);
                return {
                  ...nft,
                  ...{
                    coordinates: {
                      lat: 0,
                      lng: 0,
                    },
                  },
                };
              }
            });
            setNFTs(nfts);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    fetch(`${MORALIS_SERVER_URL}/getErc20Tokens`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        _ApplicationId: MORALIS_APPLICATION_ID,
      }), 
    }).then(async (result) => {
      if (result.ok) {
        const erc20Tokens = await result.json()
        setErc20Tokens(erc20Tokens.result)
      }
    });

  }, []);

  const getTokenName = (tokenAddress:string) => {
    let token = erc20Tokens?.find(it => it.addres === tokenAddress)
    return token ? token.symbol : "Unknown"
  }

  return {
    nfts,
    getTokenName
  };
}
