import { useEffect, useRef, useState } from "react";
import * as h3 from "h3-js";

import Modal from "./components/Modal";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import useNFTs from "./hooks/useNFTs";
import { MAPTILER_API_KEY } from "./constants";

import "./App.css";

function createPoint(points) {
  return {
    x: points[0],
    y: points[1],
  };
}

function App() {
  const earth = useRef();
  const { nfts } = useNFTs();
  const hexagonosRef = useRef([]);
  const [selectedNFT, setSelectedNFT] = useState();

  function initialize() {
    const WE = window.WE;
    earth.current = new WE.map("Earth", {
      zoom: 3.5,
      position: [20, -90],
      atmosphere: true,
    });

    var showInfo = function (e) {
      handleOnClick(e);
    };
    earth.current.on("click", showInfo);

    WE.tileLayer(
      `https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=${MAPTILER_API_KEY}`
    ).addTo(earth.current);
    // WE.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(earth);
  }

  const createHexagons = () => {
    let hexagonos = [];
    const RESOLUTION_H3 = 6;
    for (let nft of nfts) {
      if (nft.coordinates) {
        let cel = h3.geoToH3(
          nft.coordinates.lat,
          nft.coordinates.lng,
          RESOLUTION_H3
        );
        hexagonos.push({
          center: h3.h3ToGeo(cel),
          geometry: h3.h3ToGeoBoundary(cel).reverse(), // correct polygon winding
          nft: nft,
        });
      }
    }
    createObjects3d(hexagonos);
    hexagonosRef.current = hexagonos;
  };

  const createObjects3d = (hexagons) => {
    const WE = window.WE;
    const polygonOptions = {
      color: "#0ff",
      opacity: 1,
      fillColor: "#0f0",
      fillOpacity: 0.1,
      weight: 2,
    };
    for (let i = 0; i < hexagons.length && i < 10000; i += 1000) {
      for (let hexagon of hexagons.slice(i, i + 1000)) {
        setTimeout(() => {
          new Promise(() => {
            var polygonA = WE.polygon(hexagon.geometry, polygonOptions);
            polygonA.addTo(earth.current);
          });
        }, 1000);
      }
    }
  };

  const handleOnClick = (e) => {
    // alert(e.latitude + ', ' + e.longitude);
    let p = createPoint([e.latitude, e.longitude]);

    let selected = hexagonosRef.current.find((hexagon) => {
      for (let i = 2; i < hexagon.geometry.length; i++) {
        let a = createPoint(hexagon.geometry[0]);
        let b = createPoint(hexagon.geometry[i - 1]);
        let c = createPoint(hexagon.geometry[i]);
        let d = createPoint([b.x - a.x, b.y - a.y]);
        let e = createPoint([c.x - a.x, c.y - a.y]);

        let w1 =
          (e.x * (a.y - p.y) + e.y * (p.x - a.x)) / (d.x * e.y - d.y * e.x);
        let w2 = (p.y - a.y - w1 * d.y) / e.y;

        let pointInTriangle = w1 >= 0.0 && w2 >= 0.0 && w1 + w2 <= 1.0;

        if (pointInTriangle) {
          //Punto dentro del triángulo
          return true;
        }
      }
      return false;
    });

    if (selected) {
      setSelectedNFT(selected.nft);
    }
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nfts) {
      createHexagons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nfts]);

  return (
    <div id="App">
      <Header />
      <div id="Earth" />
      <Footer />
      {selectedNFT && (
        <Modal
          nft={selectedNFT}
          modalIsOpen={selectedNFT ? true : false}
          onRequestClose={() => setSelectedNFT(null)}
        />
      )}
    </div>
  );
}

export default App;
