import Modal from "react-modal";
import { ethers } from "ethers";
import styles from "./index.module.css";

import { MARKETPLACE_URL } from "../../constants";
import useNFTs from "../../hooks/useNFTs";

interface ModalNFTProps {
  nft: any;
  modalIsOpen: boolean;
  onRequestClose: () => void;
}

Modal.setAppElement("#root");
export default function ModalNFT({
  nft,
  modalIsOpen,
  onRequestClose,
}: ModalNFTProps) {
  const {getTokenName} = useNFTs();
  let value = "0";
  let token = "BNB";
  if (nft.SaleData) {
    value = ethers.utils.formatUnits(nft.SaleData.buyNowPrice);
    token = getTokenName(nft.SaleData.erc20Token);
  }
  const region = nft.attributesList.find(
    (it: { trait_type: string; value: string }) =>
      it.trait_type.toLowerCase() === "region"
  );
  const landId = nft.attributesList.find(
    (it: { trait_type: string; value: string }) =>
      it.trait_type.toLowerCase() === "land_id"
  );
  const linkToBuy = `${MARKETPLACE_URL}/nft/${nft.nftContractAddress}/${nft.tokenId}`;
  return (
    <Modal
      isOpen={modalIsOpen}
      contentLabel="Example Modal"
      style={customStyles}
      onRequestClose={onRequestClose}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            width: "100%",
            height: "40px",
            backgroundColor: "#e1e1e1",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div>
            {nft.SaleData ? (
              <span
                style={{
                  paddingRight: "30px",
                  fontWeight: "bold",
                  color: "rgb(32 201 36)",
                }}
              >
                {"\u2B22 Available"}
              </span>
            ) : (
              <span
                style={{
                  paddingRight: "30px",
                  fontWeight: "bold",
                  color: "gray",
                }}
              >
                <span style={{ color: "red" }}>{"\u2B22"}</span>
                {" Not available"}
              </span>
            )}
          </div>
          <button style={{ marginRight: 5 }} onClick={onRequestClose}>
            X
          </button>
        </div>
        <div
          style={{
            display: "flex",
            padding: "15px",
            paddingTop: "0px",
            paddingBottom: "5px",
          }}
        >
          <div className="flex justify-center items-center">
            <img
              src={nft.image}
              alt={nft.name}
              style={{
                width: 150,
                height: 150,
                objectFit: "cover",
                marginTop: "-20px",
                border: "5px solid #CCCCCC",
                backgroundColor: "#e1e1e1",
              }}
            ></img>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: "10px",
              paddingLeft: "20px",
            }}
          >
            <span>
              Owner: <strong>{`${nft.User && nft.User[0]?.username}`}</strong>
            </span>
            <span>
              Value: <strong>{`${value} (${token})`}</strong>
            </span>
            <span>
              Región: <strong>{region ? region.value : `Unknown`}</strong>
            </span>
            <span>
              Land ID: <strong>{landId ? landId.value : "Unknown"}</strong>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px 0px",
                width: "100%",
              }}
            >
              <a href={linkToBuy} target="_blank" rel="noreferrer">
                <button
                  className={
                    nft.SaleData ? styles.button : styles.buttonEnabled
                  }
                  disabled={nft.SaleData ? false : true}
                >
                  BUY NOW
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "30%",
    borderRadius: "5px",
    padding: "0px",
    fontFamily: "Rajdhani",
    fontSize: "14px",
    backgroundColor: "#f0f0f0",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
};
